import moment from 'moment';

export const formatDateTime = (dateTime: string): string => {
  return moment(String(dateTime)).format('MMM DD YYYY hh:mm a');
};

export const formatDate = (dateTime: string): string => {
  return moment(String(dateTime)).format('MMM DD, YYYY');
};

export const formatDateTimePHP = (dateTime): string => {
  return moment(String(dateTime)).format('YYYY-MM-DD HH:mm:ss');
};
