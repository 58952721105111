
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { Modules as MainModules } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { formatDateTime } from "@/core/helpers/date";
import Pagination from "@/components/Pagination.vue";
import UserSubscriptionCancelModal from "@/views/admin/UserSubscriptionCancelModal.vue";
import UserCreateModal from "@/views/admin/UserCreateModal.vue";

export default defineComponent({
  name: "AdminUsers",
  components: { Pagination, UserSubscriptionCancelModal, UserCreateModal },
  setup() {
    const store = useStore();
    const route = useRoute();

    let search = ref("");
    let currentPageURL = ref("");
    let editWorldURoleUserID = ref("");
    let internalSubscription = ref("free");
    let editInternalSubscriptionUserID = ref("");
    let cancelSubscriptionUserID = ref("");

    let editStatusUserID = ref("");

    let latest = ref(false);

    latest.value = route.query?.latest ? true : false;

    let isLoading = computed(() => {
      return store.getters[Modules.USER + "getIsLoading"];
    });
    let users = computed(() => {
      return store.getters[Modules.USER + "getUsers"];
    });
    let usersMeta = computed(() => {
      return store.getters[Modules.USER + "getMeta"];
    });
    let userRoles = computed(() => {
      return store.getters[MainModules.MASTER + "getUserRoles"];
    });

    // let filteredUsers = computed(() =>
    //   users.value.length > 0
    //     ? users.value.filter(
    //         (item) =>
    //           item.name.toUpperCase().includes(search.value.toUpperCase()) ||
    //           item.email.toUpperCase().includes(search.value.toUpperCase()) ||
    //           !search.value
    //       )
    //     : users.value
    // );

    onMounted(() => {
      setCurrentPageTitle("Users");
    });

    fetchRecords();

    function refresh() {
      fetchRecords();
    }

    function searchNow() {
      currentPageURL.value = "";
      fetchRecords();
    }

    function gotoPage(url) {
      currentPageURL.value = url;
      fetchRecords();
    }

    function fetchRecords() {
      store.dispatch(Modules.USER + Actions.FETCH_USERS, {
        search: search.value,
        latest: latest.value,
        url: currentPageURL.value,
      });
    }

    function editUserWorldRole(userID) {
      editWorldURoleUserID.value = userID;
    }

    function updateUserWorldRole(event, userID) {
      var payload = { world_role: event.target.value };
      store.dispatch(Modules.USER + Actions.UPDATE_USER_ROLE, {
        user_id: userID,
        payload: payload,
      });
    }

    function closeUserWorldRole(user_id) {
      editWorldURoleUserID.value = "";
      refresh();
    }

    function editUserInternalSubscription(userID) {
      editInternalSubscriptionUserID.value = userID;
    }

    function setUserInternalSubscription(userID) {
      if (internalSubscription.value != "free") {
        var payload = { plan_code: internalSubscription.value };
        store
          .dispatch(Modules.USER + Actions.SET_INTERNAL_SUBSCRIPTION, {
            user_id: userID,
            payload: payload,
          })
          .then(() => {
            internalSubscription.value = "free";
            editInternalSubscriptionUserID.value = "";
            refresh();
          });
      } else {
        internalSubscription.value = "free";
        editInternalSubscriptionUserID.value = "";
      }
    }

    function closeUserInternalSubscription(user_id) {
      editInternalSubscriptionUserID.value = "";
      refresh();
    }

    // Update User Status
    function editUserStatus(userID) {
      editStatusUserID.value = userID;
    }

    function updateUserStatus(userID) {
      let userIndex = Object.keys(users.value).find(
        (key) => users.value[key].id === userID
      );

      if (userIndex) {
        let userStatus = users.value[userIndex].status;

        var payload = { status: userStatus };
        store
          .dispatch(Modules.USER + Actions.UPDATE_USER_STATUS, {
            user_id: userID,
            payload: payload,
          })
          .then(() => {
            editStatusUserID.value = "";
            refresh();
          });
      }
    }

    watch(latest, (value) => {
      currentPageURL.value = "";
      fetchRecords();
    });

    return {
      formatDateTime,
      search,
      isLoading,
      users,
      usersMeta,
      latest,
      // filteredUsers,
      userRoles,
      refresh,
      searchNow,
      gotoPage,
      editWorldURoleUserID,
      editUserWorldRole,
      updateUserWorldRole,
      closeUserWorldRole,

      internalSubscription,
      editInternalSubscriptionUserID,
      editUserInternalSubscription,
      setUserInternalSubscription,
      closeUserInternalSubscription,

      editStatusUserID,
      editUserStatus,
      updateUserStatus,

      cancelSubscriptionUserID,
    };
  },
});
