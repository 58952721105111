
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SubmitButton from "@/components/SubmitButton.vue";

export default defineComponent({
  name: "UserSubscriptionCancelModal",
  components: { SubmitButton },
  props: { user_id: String },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    let isLoading = computed(() => {
      return store.getters[Modules.USER + "getIsLoading"];
    });

    let userID = ref(props.user_id);

    const modalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLButtonElement | null>(null);

    const schema = yup.object({
      cancel: yup.string().label("Cancel"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        cancel: "",
      },
      validationSchema: schema,
    });

    const { value: cancel } = useField("cancel");

    const onSubmit = handleSubmit((values) => {
      let payload = {
        user_id: userID.value,
        values: values,
      };
      return new Promise<void>((resolve) => {
        store
          .dispatch(Modules.USER + Actions.CANCEL_USER_SUBSCRIPTION, payload)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.USER + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              hideModal(modalRef.value);
              store.dispatch(Modules.USER + Actions.FETCH_USERS, {
                search: "",
                latest: "",
                url: "",
              });
            });
          })
          .catch(() => {
            setErrors(store.getters[Modules.USER + "getErrors"]);
            Swal.fire({
              text: "Failed to cancel the subscription.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .finally(() => {
            resolve();
          });
      });
    });

    watch(
      () => props.user_id,
      (newValue) => {
        userID.value = newValue;
      }
    );

    return {
      isLoading,
      cancel,

      modalRef,
      submitButton,

      meta,
      errors,
      onSubmit,
      isSubmitting,
    };
  },
});
