
import { defineComponent } from "vue";

export default defineComponent({
  name: "Pagination",
  props: ["links", "from", "to", "total"],
  methods: {
    gotoPage(url) {
      this.$emit("gotoPage", url);
    },
  },
});
