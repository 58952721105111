import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex flex-stack flex-wrap pt-10" }
const _hoisted_2 = { class: "fs-6 fw-bold text-gray-700" }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  role: "button",
  class: "page-link"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, " Showing " + _toDisplayString(_ctx.from) + " to " + _toDisplayString(_ctx.to) + " of " + _toDisplayString(_ctx.total) + " entries ", 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["page-item", { active: item.active, disabled: item.url == null }]),
          key: index,
          onClick: ($event: any) => (_ctx.gotoPage(item.url))
        }, [
          _createElementVNode("a", _hoisted_5, [
            _createElementVNode("span", {
              innerHTML: item.label
            }, null, 8, _hoisted_6)
          ])
        ], 10, _hoisted_4))
      }), 128))
    ])
  ]))
}